import { Component } from '@angular/core';
import { EntryService } from './entry.service';
import { response } from 'express';
import { ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MessageDialogComponent } from '../../common/components/message-dialog/message-dialog.component';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { StorageService } from '../../common/service/storage.service';
import { SESSION_TNC } from '../../app.constants';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { SampleReceiptDialogComponent } from './sample-receipt-dialog/sample-receipt-dialog.component';


@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrl: './entry.component.scss',
})
export class EntryComponent {
  registrationStatus: number = 0;
  entryData: any;
  token: any;
  shop: any;
  entryForm: FormGroup<any>;
  isShopIdReadonly: boolean = true;
  isFirstNameReadonly: boolean = true;
  isLastNameReadonly: boolean = true;
  isEmailReadonly: boolean = true;
  isPhoneReadonly: boolean = true;
  receipt_number: any;
  shop_id: any;
  order_amount: any;
  lucky_draw_hdr_id: any;
  crm_customer_id: any;
  shop_name: any;
  recaptchaToken: any = '';
  private reCaptchaAction = 'entrydata';
  isDisabled: boolean = true;
  paramValue: any;
  ldHdrId = 3;
  isManualEntry: boolean = false;
  dob: any;
  LdData: any;
  entry_method: any;

  constructor(
    private EntryService: EntryService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service,
    private storageService: StorageService
  ) {
    this.entryForm = new FormGroup({
      shopId: new FormControl(''),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone_number: new FormControl('', [
        Validators.required,
        Validators.maxLength(13),
        Validators.minLength(9),
      ]),
      isSubscribed: new FormControl(false, Validators.required),
      terms_conditions: new FormControl('', Validators.required),

      field1: new FormControl(''),
      field2: new FormControl(''),
      field3: new FormControl(''),
      receipt_amount: new FormControl(''),
    });
  }

  // To validate the addicational controls only if isManualEntry ture
  updateManualEntryValidators() {
    if (this.isManualEntry) {
      // Add validators if manual entry is true
      this.entryForm
        .get('field1')
        ?.setValidators([
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern('^[A-Za-z0-9]+$'),
        ]);
      this.entryForm
        .get('field2')
        ?.setValidators([
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern('^[A-Za-z0-9]+$'),
        ]);
      this.entryForm
        .get('field3')
        ?.setValidators([Validators.required, Validators.pattern('^[0-9]+$')]);
      this.entryForm.get('receipt_amount')?.setValidators(Validators.required);
    } else {
      // Clear validators if manual entry is false
      this.entryForm.get('field1')?.clearValidators();
      this.entryForm.get('field2')?.clearValidators();
      this.entryForm.get('field3')?.clearValidators();
      this.entryForm.get('receipt_amount')?.clearValidators();
    }

    // Update the form controls to trigger validation
    this.entryForm.get('field1')?.updateValueAndValidity();
    this.entryForm.get('field2')?.updateValueAndValidity();
    this.entryForm.get('field3')?.updateValueAndValidity();
    this.entryForm.get('receipt_amount')?.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.recaptchaToken = null;
  }

  ngOnInit() {
    const paramValue = this.route.snapshot.queryParams['p'];
    if (paramValue === null || paramValue === undefined) {
      this.isManualEntry = true;
      this.getActiveLd();
      this.entry_method = 2;
    } else {
      this.getValidatedData(paramValue);
      this.entryForm.disable();
      this.entry_method = 1;
    }
    this.updateManualEntryValidators();
  }
  
  //  to take the entry data of customers
  getValidatedData(paramValue: any): any {
    this.EntryService.getValidatedData(
      encodeURIComponent(paramValue)
    ).subscribe({
      next: (response: any) => {
        if (response.status == 'SUCCESS') {
          this.entryData = response;
          this.entryForm.patchValue({
            shopId: this.entryData?.data?.shop?.id ?? '',
            firstName: this.entryData?.data?.customer?.first_name ?? '',
            lastName: this.entryData?.data?.customer?.last_name ?? '',
            email: this.entryData?.data?.customer?.email ?? '',
            phone_number: this.entryData?.data?.customer?.phone_number ?? '',
          });
          this.receipt_number = this.entryData.data.r_no;
          this.shop_id = this.entryData.data.shop.id;
          this.order_amount = this.entryData.data.order_amount;
          this.lucky_draw_hdr_id = this.entryData.data.lucky_draw.id;
          this.crm_customer_id = this.entryData?.data?.customer?.id;
          this.shop_name = this.entryData?.data?.shop?.name;
          this.isDisabled = false;
          this.entryForm.enable();
          this.registrationStatus = 0;
          this.storageService.saveToSession(
            SESSION_TNC,
            this.entryData.data.terms_conditions
          );
        } else if (response.status === 'RECEIPT_NO_ALREADY_REGISTERD') {
          const message = response.message;
          this.showDialog({ title: 'Already Registred', content: message });
          this.isDisabled = true;
          this.entryForm.disable();
          this.registrationStatus = 2;
        } else {
          const message = response.message;
          this.showDialog({ title: 'Failed to validate', content: message });
          this.entryForm.disable();
          this.registrationStatus = 2;
        }
      },
      error: (err: any) => {
        const message = "We are unable to register your details. Please try later. Sorry for the inconvenience.";
        this.showDialog({ title: 'Something went wrong!!', content: message });
        console.error('Error fetching data:', err);
        this.isDisabled = true;
        this.entryForm.disable();
        this.registrationStatus = 2;
      },
    });
  }

  /**
   * to get LuckyDraw id when paramValue value is null
   */
  getActiveLd(): any {
    this.EntryService.getActiveLd().subscribe({
      next: (response: any) => {
        if (response.status == 'SUCCESS') {
          this.LdData = response;
          const firstId = response.data?.id;
          this.lucky_draw_hdr_id = firstId;
        }else{
          const message = response.message;
          this.showDialog({ title: 'No Active Draw', content: message });
        }
      },
    });
  }

  //  to Register in luckydraw
  onRegister(): void {
    if (!this.entryForm.valid) {
      this.entryForm.markAllAsTouched();
      if (!this.entryForm?.get('terms_conditions')?.value) {
        this.showDialog({
          title: 'Terms & Conditions',
          content: 'To participate, please accept the Terms & Conditions.',
        });
      }
    } else {
      this.order_amount = (this.isManualEntry)?this.entryForm.get('receipt_amount')?.value: this.order_amount ;
      const field1 = this.entryForm.get('field1')?.value;
      const field2 = this.entryForm.get('field2')?.value;
      const field3 = this.entryForm.get('field3')?.value;

      if (field1 && field2 && field3) {
        // Combine into a date string
        this.receipt_number = `${field1}-${field2}-${field3}`;
      } else {
     
      }

      const entryDetails = {
        crm_customer_id: this.crm_customer_id ?? 0,
        f_name: this.entryForm.controls['firstName'].value,
        l_name: this.entryForm.controls['lastName'].value,
        email: this.entryForm.controls['email'].value,
        phone_number: this.entryForm.controls['phone_number'].value,
        receipt_number: this.receipt_number,
        shop_id: this.shop_id,
        order_amount: this.order_amount,
        lucky_draw_hdr_id: this.lucky_draw_hdr_id,
        is_subscribed: this.entryForm.value.isSubscribed ? 1 : 0,
        shop_name: this.shop_name,
        entry_method: this.entry_method, 
      };
      this.registrationStatus = 1;
      this.recaptchaV3Service
        .execute(this.reCaptchaAction)
        .subscribe((token) => {
          this.recaptchaToken = token;
          this.EntryService.luckyDrawRegistration({
            ...entryDetails,
            g_token: this.recaptchaToken,
          }).subscribe({
            next: (response) => {
              if (response.status === 'SUCCESS') {
                const message = "You're now into the draw. Good Luck!!";
                this.showDialog({ title: 'Thank you for your entry!', content: message });
                this.registrationStatus = 0;
              } else if (response.status === 'RECEIPT_NO_ALREADY_REGISTERD') {
                const message = response.message;
                this.showDialog({
                  title: 'Already Registred',
                  content: message,
                });
                this.registrationStatus = 0;
              } else if (response.status === 'FAILED_TO_INSERT') {
                const message = "We are unable to register your details. Please make sure you have entered correct details such as receipt number, amount etc.";
                this.showDialog({
                  title: 'Something went wrong!!',
                  content: message,
                });
                this.registrationStatus = 0;
              } else {
                const message = response.message;
                this.showDialog({
                  title: 'Failed to register',
                  content: message,
                });
                this.registrationStatus = 0;
              }
            },
            error: (error) => {
              const message = "We are unable to register your details. Please make sure you have entered correct details such as receipt number, amount etc.";
              this.showDialog({
                title: 'Something went wrong!!',
                content: message,
              });
              this.registrationStatus = 0;
            },
          });
        });
    }
  }

  // Dialog box displaying messages
  showDialog(message: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = message;
    dialogConfig.panelClass = 'app-message-dialog';
    const dialogRef = this.dialog.open(MessageDialogComponent, dialogConfig);
  }

  // to show the sample image of receipt number
  showHelpDialog(): void {
    const dialogRef = this.dialog.open(SampleReceiptDialogComponent);
  }

  /** to prevent typing charaters other than numbers  */
  preventNonNumeric(event: KeyboardEvent) {
    if (!/^[0-9]*$/.test(event.key)) {
      event.preventDefault();
    }
  }

  /** to prevent typing charaters other than numbers in receipt amount  */
  preventNonNumericInRcAmount(event: KeyboardEvent) {
    const key = event.key;
    if (!/^[0-9.]*$/.test(event.key)) {
      event.preventDefault();
    }

    const inputValue = (event.target as HTMLInputElement).value;
    if (key === '.' && inputValue.includes('.')) {
      event.preventDefault();
    }
  }
}

  

 
