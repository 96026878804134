
<h3 mat-dialog-title style="font-size: 16px; font-weight: 500;">
    {{data.title}}
  </h3>
  
  <div mat-dialog-content>
    <p>{{data.content}}</p>
  </div>
  
  <div mat-dialog-actions class="mdc-action-container">
    <button mat-raised-button color="primary" (click)="close()">OK</button>
  </div>

